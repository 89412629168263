* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  overflow: "hidden";
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 600;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "noto-sans-regular";
  src: url(assets/fonts/Noto_Sans/NotoSans-Regular.ttf);
}
